import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Loader from "../layout/loader";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import ThemeCustomize from "../layout/theme-customizer";
import EvaluationInCourse from "./EvaluationInCourse";
import Document from "./document/Document";

const LayoutApp = () => {
	const token = localStorage.getItem("token");
	if (!token) {
		window.location.href = "/login";
	}

	console.warn = () => {};

	return (
		<Fragment>
			<Loader />
			<div className="page-wrapper compact-wrapper" id="pageWrapper">
				<Header />
				<div className="page-body-wrapper sidebar-icon">
					<Sidebar />
					<div className="page-body">
						<Outlet />
					</div>
					<Footer />
					<ThemeCustomize />
					<EvaluationInCourse />
					<Document />
				</div>
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
		</Fragment>
	);
};

export default LayoutApp;
