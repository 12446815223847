import React, { Fragment, useState } from "react";
import { LogIn, User, Bell } from "react-feather";
import { useNavigate } from "react-router-dom";
import man from "assets/images/dashboard/profile.png";
import { Notification, DeliveryProcessing, OrderComplete, TicketsGenerated, DeliveryComplete, CheckAllNotification } from "constant";

const Rightbar = (props) => {
	let navigate = useNavigate();

	const [notificationDropDown, setNotificationDropDown] = useState(false);

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("full_name");
		localStorage.removeItem("slug");
		localStorage.removeItem("expiration_date");
		localStorage.removeItem("customer");
		localStorage.removeItem("user_id");
		localStorage.removeItem("screens");
		localStorage.removeItem("roles");

		window.location.href = `/login`;
	};

	return (
		<Fragment>
			<div className="nav-right col-12 pull-right right-header p-0">
				<ul className="nav-menus">
					<li className="onhover-dropdown" style={{ display: "none" }}>
						<div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}>
							<Bell />
							<span className="badge badge-pill badge-secondary">2</span>
						</div>
						<ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
							<li>
								<Bell />
								<h6 className="f-18 mb-0">{Notification}</h6>
							</li>
							<li>
								<p>
									<i className="fa fa-circle-o mr-3 font-primary"> </i>
									{DeliveryProcessing} <span className="pull-right">{"10 min."}</span>
								</p>
							</li>
							<li>
								<p>
									<i className="fa fa-circle-o mr-3 font-success"></i>
									{OrderComplete}
									<span className="pull-right">{"1 hr"}</span>
								</p>
							</li>
							<li>
								<p>
									<i className="fa fa-circle-o mr-3 font-info"></i>
									{TicketsGenerated}
									<span className="pull-right">{"3 hr"}</span>
								</p>
							</li>
							<li>
								<p>
									<i className="fa fa-circle-o mr-3 font-danger"></i>
									{DeliveryComplete}
									<span className="pull-right">{"6 hr"}</span>
								</p>
							</li>
							<li>
								<button className="btn btn-primary">{CheckAllNotification}</button>
							</li>
						</ul>
					</li>
					<li className="profile-nav onhover-dropdown p-0">
						<div className="media profile-media">
							<img className="b-r-10" src={man} alt="" />
							<div className="media-body">
								<span>{localStorage.getItem("full_name")}</span>
								{localStorage.getItem("customer") && localStorage.getItem("customer") !== "false" ? (
									<p className="mb-0 font-roboto">
										{localStorage.getItem("customer")} <i className="middle fa fa-angle-down"></i>
									</p>
								) : (
									<p className="mb-0 font-roboto">Estudiante</p>
								)}
							</div>
						</div>
						<ul className="profile-dropdown onhover-show-div">
							<li onClick={() => navigate("/profile/" + localStorage.getItem("slug"))}>
								<User />
								<span>Perfil</span>
							</li>
							<li onClick={() => handleLogout()}>
								<LogIn />
								<span>Cerrar Sesión</span>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</Fragment>
	);
};
export default Rightbar;
