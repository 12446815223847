export const MENUITEMS = [
	{
		menutitle: "BASE",
		menucontent: "",
		Items: localStorage.getItem("screens") ? JSON.parse(localStorage.getItem("screens")) : [],
	},
];

export const ICONS = {
	Clientes: "icofont icofont-university",
	Roles: "icofont icofont-user-alt-2",
	Usuarios: "icofont icofont-users-alt-2",
	Pantallas: "icofont icofont-settings-alt",
	Menús: "icofont icofont-ui-settings",
	Asignaturas: "icofont icofont-electron",
	"Niveles de Preguntas": "icofont icofont-education",
	"Categorías de Preguntas": "icofont icofont-test-bulb",
	Preguntas: "icofont icofont-paper",
	Cursos: "icofont icofont-brainstorming",
	Estudiantes: "icofont icofont-group-students",
	Evaluaciones: "icofont icofont-read-book",
	"Registrar Curso": "icofont icofont-pencil-alt-5",
	"Mis Cursos": "icofont icofont-book-alt",
	"Mis Evaluaciones": "icofont icofont-award",
	"Contenidos de Preguntas": "icofont icofont-atom",
	"Objetivos de Preguntas": "icofont icofont-microscope-alt",
};
